
import Vue from 'vue';
import PdfExport from '@/components/analytics/widgets/PdfExport.vue';
import ExcelExport from '@/components/analytics/widgets/ExcelExport.vue';
import GptExport from '@/components/analytics/widgets/GptExport.vue';

export default Vue.extend({
  name: 'ReportExportMenu',

  components: {
    PdfExport,
    ExcelExport,
    GptExport,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    itemsVisibility: {
      type: Object,
      default: () => ({
        pdf: false,
        excel: false,
        pptx: false,
        gpt: false,
      }),
    },
  },
});
