
import Vue from 'vue';
import TTLoader from '@/components/ui/TTLoader.vue';
import { ReportType } from '@/constants/reportService';
import * as reportServiceApi from '@/services/api/report-service';
import isValidEmail from '@/helpers/emailValidator';
import errorHandler from '@/helpers/errorHandler';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

export default Vue.extend({
  name: 'GptExportDialog',

  components: {
    TTLoader,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      loading: false,
      formData: { ...this.getInitialFormValue() },
      isShowHedgehog: false,
      ReportType,
    };
  },

  computed: {
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    validationRules() {
      return {
        notEmpty: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
      };
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    isEmailValid(): boolean {
      return isValidEmail(this.formData.email);
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.resetForm();
      }
    },
  },

  methods: {
    getInitialFormValue() {
      return {
        email: '',
      };
    },
    async submitForm(reportType) {
      if (!this.isEmailValid) return;
      try {
        this.loading = true;
        await reportServiceApi.reportGptCreate({
          name: this.$t('analytics.pdf.gptExportDialog.gptReportName'),
          surveyId: this.surveyId,
          userId: this.userId,
          email: this.formData.email,
          fileExtension: reportType || ReportType.PDF,
        });
        this.isShowHedgehog = true;
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    resetForm() {
      this.form?.resetValidation();
      this.formData = { ...this.getInitialFormValue() };
      this.isShowHedgehog = false;
      this.$emit('cancel');
    },
    close() {
      this.resetForm();
      this.$emit('cancel');
    },
  },
});
