<template>
  <div>
    <VListItem
      :disabled="disabled"
      class="pa-0"
      data-test-label="pdfExport"
      @click.stop="isShowPdfExportDialog = true"
    >
      <VListItemTitle
        class="list-item-text__wrapper px-5 tt-text-body-2 d-flex align-center"
        :class="{ 'tt-light-mono-24--text' : disabled, 'cursor-pointer' : !disabled }"
        color="white"
        min-width="auto"
      >
        <VIcon
          size="20"
          class="mr-3"
          :color="disabled ? 'tt-light-mono-24' : ''"
        >
          fal fa-file-pdf
        </VIcon>
        <span>{{ $t('reportExportMenu.exportToPdf') }}</span>
      </VListItemTitle>
    </VListItem>
    <PdfExportDialog
      :show.sync="isShowPdfExportDialog"
      :survey-id="surveyId"
      :user-id="userId"
      data-test-label="pdfExportDialog"
      @cancel="isShowPdfExportDialog = false"
    />
  </div>
</template>

<script>
import PdfExportDialog from '@/components/analytics/widgets/PdfExportDialog.vue';

export default {
  name: 'PdfExport',
  components: {
    PdfExportDialog,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isShowPdfExportDialog: false,
    };
  },
};
</script>
