
import Vue from 'vue';
import TTActionDialog from '@/components/ui/TTDialog/TTActionDialog.vue';
import DevelopmentTaskEditForm from '@/components/analytics/feedback/developmentTasks/DevelopmentTaskEditForm.vue';

export default Vue.extend({
  name: 'DevelopmentTaskEditDialog',

  components: {
    TTActionDialog,
    DevelopmentTaskEditForm,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    taskId: {
      type: [String, Number],
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
  },

  computed: {
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    isCreating(): boolean {
      return !(this.taskId);
    },
    headerText() {
      return this.isCreating
        ? this.$t('analytics.developmentTasks.addTask')
        : this.$t('analytics.developmentTasks.editTask');
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.$refs.form.resetForm();
      }
    },
  },
});
