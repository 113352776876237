// eslint-disable-next-line
export enum ArchiveStatuses {
  INIT = 'init',
  PROCESSING = 'processing',
  ARCHIVING = 'archiving',
  DONE = 'done',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
}

export const ReportType = {
  PDF: 'pdf',
  EXCEL: 'excel',
  PPTX: 'pptx',
  GPT: 'gpt',
};
